<template>
    <div>
        <h2>Key Skills</h2>
        <ul>
            <li>C++ (including C++17)</li>
            <li>Python</li>
            <li>C</li>
            <li>Linux / GCC / GDB</li>
            <li>TCP / UDP / SCTP</li>
            <li>git / svn</li>
            <li>Real-time Operating Systems</li>
        </ul>
        <h2>Actively Progressing</h2>
        <ul>
            <li>Vue.js</li>
            <li>Node.js</li>
            <li>MongoDB</li>
            <li>Django</li>
            <li>AWS / CDK</li>
            <li>Rust</li>
            <li>Windows / Visual Studio</li>
            <li>Docker</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'KeySkills',
}
</script>

<style scoped>
ul {
    text-align: left;
}

li {
    line-height: 1.8em;
}

div {
    text-align: left;
    padding: 20px;
}
</style>