<template>
    <div>
        <h1>{{ name }}</h1>
        <hr>
        <h2>{{ description }}</h2>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'BannerItem',
    data() {
        return {
            name: '',
            description: ''
        }
    },
    mounted() {
        axios
            // .get('http://127.0.0.1:8081/api/cv')
            .get('/api/cv')
            .catch((error) => { console.log(error)})
            .then((response) => {
                console.log(response)
                this.name = response.data.name;
                this.description = response.data.description;
            })
    }
}
</script>

<style scoped>
div {
    background-color: rgb(70,70,80);
    color: white;
    padding: 20px;
    margin: 0px;
    text-align: center;
}
hr {
    width: 64px;
}
</style>