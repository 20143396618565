<template>
    <div>
        <table>
            <tr>
                <td class="left">
                    <HeadShot />
                </td>
                <td class="right">
                    <BannerItem />
                    <DetailsItem />
                </td>
            </tr>
            <tr>
                <td class="left">
                    <KeySkills/>
                </td>
                <td class="right">
                    <PersonalMessage/>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import HeadShot from './HeadShot.vue';
import BannerItem from './BannerItem.vue';
import DetailsItem from './DetailsItem.vue';
import PersonalMessage from './PersonalMessage.vue';
import KeySkills from './KeySkills.vue';

export default {
    name: 'CurriculumVitae',
    components: {
        HeadShot,
        BannerItem,
        DetailsItem,
        PersonalMessage,
        KeySkills,
    }
}
</script>

<style scoped>
table {
    width: 100%;
    max-width: 995px;
}

td {
    margin: 0px;
    padding: 0px;
}

tr {
    vertical-align: top;
}

.left {
    width: 30%;
}

.right {
    width: 70%;
    
    max-width: 100px;
}
</style>