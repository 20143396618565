<template>
  <CurriculumVitae/>
</template>

<script>

import CurriculumVitae from './components/CurriculumVitae.vue'

export default {
  name: 'App',
  components: {
    CurriculumVitae
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Raleway');

#app {
  font-family: 'Raleway', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 30px;
}
</style>
