<template>
    <img src="../assets/timc-web.jpg">
</template>

<script>
export default {
    name: 'HeadShot',
}
</script>

<style scoped>
img {
    max-width: 300px;
    min-width: 100px;
}

</style>