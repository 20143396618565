<template>
    <div>

        <h2>Message</h2>
        <p>
            There's one thing you should know about me: I love software, and developing software as part of a
            team.
        </p>

        <p>
            I'm an open, hard-working self-starter that naturally finds a gap and gets to work. I love working
            in the kind of environments that challenge us to be the best communicators, collaborators, engineers
            and leaders that we can be.
        </p>


        <h2>Profile</h2>
        <ul>
            <li>Seasoned C++ software architect and developer.</li>
            <li>Long history of developing performance-sensitive code.</li>
            <li>A bona-fide independent self-starter.</li>
            <li>A warm and professional communicator.</li>
            <li>Articulate and pragmatic.</li>
            <li>Combines technical depth with a genuine desire to nurture both individuals and teams.</li>
            <li>An exceptional thinker, backed by the technical muscle to rapidly bring ideas to life.</li>
            <li>Designed, spearheaded and shipped cornerstone technologies for world-beating Hi-Fi and telecommunications
                products.</li>
            <li>Strong experience in modern software development environments.</li>
            <li>15 years of design and implementation of systems using C++ and Python.</li>
            <li>Extensive experience with Linux-based environments.</li>
        </ul>


        <h2>Skills</h2>

        <ul>
            <li>
                Software Architecture and Development
                <ul>
                    <li>Object-oriented design with SOLID principles</li>
                    <li>Single-threaded and event-driven architectures</li>
                    <li>Multi-threaded architectures</li>
                    <li>Hard real-time architectures</li>
                    <li>Distributed architectures</li>
                    <li>Embedded Systems</li>
                    <li>Test-driven development</li>
                    <li>Behaviour-driven development</li>
                    <li>Lock-free Programming</li>
                </ul>
            </li>
            <li>Technical Leadership
                <ul>

                    <li>Strong, collaborative leadership style</li>
                    <li>Confident and open technical interviewing</li>
                    <li>Honest and supportive mentorship</li>
                    <li>Believes in the power of agile methodologies</li>
                    <li>A strong advocate for the considered application of best practice</li>
                </ul>
            </li>
        </ul>

        <h2>Recent Professional Work</h2>

        <h3>Technical Lead: Census 2023 Data Processing Sep 22 - Apr 23</h3>
        <h4>Stats NZ Tatauranga Aotearoa</h4>

        <p>Technical Lead for the delivery phase of Census 2023 Data Processing system.</p>
        <ul>
            <li>Hired and directed 8 software contractors to get project delivery back on schedule.</li>
            <li>Ran the team, set the agenda.</li>
            <li>Worked with project management to create a credible delivery plan.</li>
            <li>Directed the work of 4 additional test automation engineers.</li>
            <li>Worked with internal customers to deliver on schedule, and respond to changing needs.</li>
            <li>Enabled a fantastic, professional, delivery-focused team culture.</li>
        </ul>

        <hr>

        <h3>Lead Contractor for Hi-Fi Prototype Aug 21 - Dec 21</h3>
        <h4>Plinius Audio Limited</h4>

        <p>Advised on technical and commercial strategy, and worked with our client's platform partners to meet the client's
            commercial goals.</p>
        <ul>
            <li>Initial platform investigation.</li>
            <li>Packaged a brittle and tedious embedded build process using docker.</li>
            <li>Updated legacy iOS app by resolving multiple memory management issues.</li>
        </ul>

        <hr>

        <h3>Technical Lead, Internal Team Project Aug 21</h3>
        <h4>Qamcom Research and Technology NZ Limited</h4>

        <p>Product definition, software architecture and overall technical leadership for a full-team, week-long internal
            project.</p>
        <ul>
            <li>Low-latency collaborative software prototype.</li>
            <li>Python backend.</li>
            <li>Vue.js frontend.</li>
            <li>Successful globe-spanning interactive demo with CEO </li>
        </ul>

        <hr>

        <h3>Software Contractor on CTR8740 High-Availability Upgrade Feb 19 - Sep 21</h3>
        <h4>Aviat Networks (NZ) Limited</h4>

        <p>Worked within a small team of 6 to upgrade the Aviat Networks CTR8740 microwave router for high-availability
            operation.</p>
        <ul>
            <li>Designed and implemented a C++ framework for exposing and consuming generic, per-chassis services.</li>
            <li>Designed and led the implementation of the over-the-air upgrade system.</li>
            <li>Designed and implemented a distributed, composable hardware abstraction layer</li>
        </ul>

        <hr>

        <h3>Senior Embedded Software Developer Dec 06 - Dec 18</h3>
        <h4>Linn Products Limited</h4>

        <p>Joined Linn's initial Digital Streamer team in 2006, and spent over a decade making major contributions to the
            underlying platforms, which eventually spanned over 23 products.</p>
        <ul>
            <li>Led the effort to port our existing code to run on Linux, as part of Linn's first embedded-Linux product:
                Selekt DSM.</li>
            <li>Designed and lead the implementation of a modern over-the-air upgrade system.</li>
            <li>Designed and implemented the hardware abstraction layer used by over 23 products.</li>
        </ul>

        <h2>Education</h2>

        <h3>Master of Electrical and Electronic Engineering 2001 - 2003</h3>
        <h4>University Of Canterbury</h4>
        <p>Thesis: "Interpretation and Visualisation of Completely Polarimetric Data from Ground Probing Radar Targets"</p>


        <h3>Bachelor of Electrical and Electronic Engineering (Hons) 1999 - 2001</h3>
        <h4>University Of Canterbury</h4>
        <p>Thesis: "Interpretation and Visualisation of Completely Polarimetric Data from Ground Probing Radar Targets"</p>

    </div>
</template>

<script>
export default {
    name: 'PersonalMessage',
}
</script>

<style scoped>
ul {
    text-align: left;
}

li {
    line-height: 1.8em;
}

div {
    text-align: left;
    padding: 20px;
}

/* h2 {
    text-decoration: underline;
} */
hr {
    width: 80%;
    text-align: left;
}
</style>