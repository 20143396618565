<template>
    <div>
        <h2>Contact</h2>
        <ul>
            <li>Featherston, New Zealand</li>
            <li><a href="mailto:tim.cussins@compound-systems.nz">tim.cussins@compound-systems.nz</a></li>
            <li><a href="tel:+64225439193">+64 (0) 22 543 9193</a></li>
            <li><a href="https://www.linkedin.com/in/tim-cussins/">LinkedIn</a> </li>
            <li><a href="https://git.sr.ht/~volumedigital/">SourceHut public software repositories</a></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'DetailsItem',
}
</script>

<style scoped>
ul {
    text-align: left;
}

li {
    line-height: 1.8em;
}
div {
    text-align: left;
    padding: 20px;
}
a {
    text-decoration: none;
}
</style>